import { useDispatch, useSelector } from 'react-redux';
import Router from 'next/router';
import { setActiveStep } from '@/redux/checkout/checkoutReducer';

const useCheckoutRouter = (from) => {
  const checkout = useSelector((state) => state.checkout);
  const dispatch = useDispatch();
  const personalInfo = (info) => {
    if (info?.first_name && info?.last_name && info?.dni && info?.phone) {
      Router.push('/checkout/confirm-data');
    } else {
      Router.push('/checkout/personal-info');
    }
  };

  const addressCheck = (info) => {
    if (
      info?.city &&
      (info?.number || info?.without_address_number) &&
      info?.state_id &&
      info?.zipcode &&
      info?.street
    ) {
      Router.push('/checkout/confirm-address');
    } else Router.push('/checkout/shipping-method');
  };

  const location = {
    home: () => {
      if (checkout.logged) {
        personalInfo(checkout.personalInfo);
      } else {
        Router.push('/checkout/mail-login');
      }
    },
    login: () => {
      Router.back();
    },
    mailLogin: (args) => {
      personalInfo(args.personalInfo);
    },
    personalData: (arg) => {
      if (arg === 'changePersonalInfo') {
        if (checkout.logged) {
          Router.push('/checkout/personal-info');
        } else {
          window.localStorage.setItem('redirectTo', '/checkout/personal-info');
          Router.push('/checkout/login-checkout');
        }
      } else if (
        arg === 'setPersonalInfo' &&
        checkout.activeStep === 'Review'
      ) {
        Router.push('/checkout/review');
      } else {
        addressCheck(checkout.shippingAddress);
      }
    },
    cvvCode: () => {
      Router.push('/checkout/installments');
    },
    cardSelection: (arg) => {
      if (arg === 'new card') {
        Router.push('/checkout/payment');
      } else if (arg === 'complete') {
        Router.push('/checkout/review');
      } else {
        Router.push('/checkout/cvv-code');
      }
    },
    shippingAddress: (arg) => {
      if (!arg) {
        if (checkout.activeStep === 'Review') {
          Router.push('/checkout/review');
        } else {
          Router.push('/checkout/payment-method');
        }
      } else {
        if (arg === 'billingAddress') {
          Router.push('/checkout/billing-address-form');
        }
        if (arg === 'changeBillingAddress') {
          Router.push('/checkout/billing-address-form');
        }
        if (arg === 'changeShippingAddress') {
          Router.push('/checkout/shipping-method');
        }
      }
    },
    shippingMethod: () => {
      Router.push('/checkout/shipping-info');
    },
    paymentMethod: () => {
      if (checkout?.personalInfo?.tokenized_cards?.length > 0) {
        Router.push('/checkout/select-cards');
      } else {
        Router.push('/checkout/payment');
      }
    },
    LoginCheckout: () => {
      const redirectTo = window.localStorage.getItem('redirectTo');
      Router.push(redirectTo);
    },
    billingAddress: () => {
      if (checkout.activeStep === 'Review') {
        Router.push('/checkout/review');
      } else {
        Router.push('/checkout/payment-method');
      }
    },
    paymentInfo: () => {
      Router.push('/checkout/review');
    },
    paymentInfoMobile: () => {
      Router.push('/checkout/installments');
    },
    review: (arg) => {
      if (!arg) {
        Router.push('/checkout/finish');
      } else {
        dispatch(setActiveStep('Review'));
        if (arg === 'changePersonalInfo') {
          if (checkout.logged || checkout.newUser) {
            Router.push('/checkout/personal-info');
          } else {
            window.localStorage.setItem(
              'redirectTo',
              '/checkout/personal-info'
            );
            Router.push('/checkout/login-checkout');
          }
        }
        if (arg === 'changePaymentInfo') {
          Router.push('/checkout/payment-method');
        }
        if (arg === 'changeShippingAddress') {
          Router.push('/checkout/shipping-method');
        }
      }
    }
  };

  return location[from];
};

export default useCheckoutRouter;
