import { useEffect } from 'react';

const useFixBody = () => {
  useEffect(() => {
    const [body] = document.getElementsByTagName('body');
    body.classList.add('inactive');
    return () => {
      body.classList.remove('inactive');
    };
  }, []);
};
export default useFixBody;
