import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Button, Text } from '@/components/atoms';
import styles from './cuponCode.module.scss';
import cartAPI from '@/api/cart';
import { loadCartInfo } from '@/redux/cart/cartReducer';

const CuponCode = () => {
  const [cupon, setCupon] = useState('');
  const dispatch = useDispatch();
  const store = useSelector((state) => state.store);

  const sendCupon = async () => {
    if (cupon.length > 0) {
      const response = await cartAPI.setCuponCode(cupon, store);
      if (response.success) {
        dispatch(loadCartInfo(response.data));
        setCupon('');
      }
    }
  };

  const formClass = cn({
    [styles.form]: true
  });

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Text
          id="sendCupon"
          textColor="var(--color-primary)"
          withOutPadding
          style={{
            height: '100%',
            justifyContent: 'flex-end',
            width: 'auto'
          }}
          textStyle={{ fontSize: '12px' }}
          className="fullWidth"
        >
          ¿Tenés un código de descuento?
        </Text>{' '}
      </div>
      <div className={formClass}>
        <input
          id="cuponCode input"
          name="cuponCode"
          placeholder="Ingresá tu código"
          style={{
            width: '100%',
            borderRadius: '5px',
            paddingLeft: '5px',
            height: '32px',
            fontSize: '12px',
            marginTop: '5px',
            border: 'none'
          }}
          type="text"
          value={cupon}
          onChange={(e) => setCupon(e.target.value)}
        />

        <Button
          id="sendCupon"
          label="Validar código"
          textColor="var(--color-primary)"
          withOutPadding
          style={{
            height: '22px',
            alignSelf: 'flex-end',
            width: 'fit-content',
            textDecoration: 'underline',
            color: 'var(--color-primary)'
          }}
          textStyle={{ fontSize: '12px' }}
          onClick={sendCupon}
        />
      </div>
    </div>
  );
};

export default CuponCode;
