import axios from 'axios';

const axiosSpsInstance = axios.create();

// crear una instancia de axios solamente para usarse con SPS
// le ponemos el apikey y la base url
axiosSpsInstance.interceptors.request.use(
  async (config) => {
    config.baseURL = process.env.NEXT_PUBLIC_DECIDIR_URL;
    config.headers = {
      apikey: process.env.NEXT_PUBLIC_DECIDIR_PUBLIC_KEY,
      'Content-Type': 'application/json'
    };

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default axiosSpsInstance;
