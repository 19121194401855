// https://medium.com/@kevinfelisilda/click-outside-element-event-using-react-hooks-2c540814b661
import { useEffect } from 'react';

const useOutsideClick = (ref, callback, show = true) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    // Time out para que no ejecute el callback con el primer click
    if (show) {
      setTimeout(() => {
        document.addEventListener('click', handleClick, { passive: true });
      }, 10);
    }

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [show]);
};

export default useOutsideClick;
